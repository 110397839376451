import PropTypes from 'prop-types';

// material-ui
import { Box, Card } from '@mui/material';


// ==============================|| AUTHENTICATION - CARD WRAPPER ||============================== //

const AuthCard = ({ children, ...other }: any) => (
  <Card
    sx={{
      maxWidth: { xs: 400, lg: 475 },
      margin: { xs: 2.5, md: 3 },
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%'
      }
    }}
    {...other}
    variant="outlined"
  >
    <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>{children}</Box>
  </Card>
);

AuthCard.propTypes = {
  children: PropTypes.node
};

export default AuthCard;
