import React from 'react';
import Login from './features/login/Login';
import { useAppSelector } from './app/hooks';

import {
  getLoginStatus
} from './features/login/loginSlice';
import ArticleList from './features/article-list/ArticleList';
import {
  Grid,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthFooter from './components/AuthFooter';
import Logo from './components/Logo/Logo';

function App() {
  const loginStatus = useAppSelector(getLoginStatus);
  return (
    <div className="App">
      <header className="App-header">
        {loginStatus === 'isAuthenticated' ? 
        <Grid
        container
          direction="column">
          <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
            <Logo />
          </Grid>
          <Grid item xs={12}>
            <Grid
              // item
              container
              justifyContent="center"
              // alignItems="center"
            >
              <ArticleList />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
            <AuthFooter />
          </Grid>
        </Grid> : <Login />}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loginStatus === 'loading'}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      </header>
    </div>
  );
}

export default App;
