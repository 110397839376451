import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Button, Card, Typography, LinearProgress } from '@mui/material';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  logout,
  getLoginCredentials
} from '../login/loginSlice';

interface Column {
  id: 'pk' | 'name' | 'weight' | 'counter' | 'status' | 'list_price' | 'net_price';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'center';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'pk', align: 'left', label: 'Article' },
  { id: 'name', label: 'Name' },
  { id: 'weight', align: 'center', label: '[kg]' },
  { id: 'status', align: 'center', label: 'Info' },
  {
    id: 'counter',
    label: 'in Stock',
    align: 'center',
    format: (value: number) => value.toString(),
  },
  {
    id: 'list_price',
    label: 'list Price',
    align: 'right',
    format: (value: number) => value.toString() + '€',
  },
  {
    id: 'net_price',
    label: 'net Price',
    align: 'right',
    format: (value: number) => value.toString() + '€',
  }
];

interface Data {
  pk: string;
  name: string;
  weight: number;
  status: string;
  counter: number;
  list_price: number;
  net_price: number;
}

interface AbsResponse {
  items: Data[];
  page: number;
  size: number;
  total: number;
  lang: string;
}

interface QueryData {
  search_number: string;
  search_text: string;
  page: number;
}

export default function ArticleList() {
  const loginCredentials = useAppSelector(getLoginCredentials);
  const dispatch = useAppDispatch();

  const [absData, setData] = React.useState<AbsResponse>({
    items: [],
    page: 0,
    size: 0,
    total: 0,
    lang: 'D',
  });

  const [query, setQuery] = React.useState<QueryData>(
    {
      search_number: '',
      search_text: '',
      page: 0
    } as QueryData
  );

  const [isLoading, setIsLoading] = React.useState(false);
  let abs_api = axios.create({
    baseURL: "https://api.atlanta-service.com/",
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const fetchData = (query: QueryData) => {
    abs_api({
      method: 'post',
      url: 'store/items',
      headers: {
        authorization: `Bearer ${loginCredentials.token}`
      },
      data: query
    }).then(function (response) {
      setData(response.data);
      setIsLoading(false);
    })
      .catch(function (error) {
        // To Login Page
        if (error.response.status === 401) {
          dispatch(logout())
        }
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setQuery({ ...query, page: newPage })
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({ ...query, [event.target.id]: event.target.value, page: 0 })
  }

  React.useEffect(() => {
    setIsLoading(true);
    const timeOutId = setTimeout(() => fetchData(query), 600);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <Card
      sx={{
        margin: { xs: 1, md: 3 },
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      variant="outlined"
    >
      <Box sx={{ p: { xs: 1, sm: 3, md: 4 } }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography color="text.secondary" variant="h4" component="h4" gutterBottom>
            Atlanta Teile Info
          </Typography>
          <Button onClick={() => dispatch(logout())}>Logout</Button>
        </Stack>
        <Typography sx={{ pb: 2 }} variant="body1">
          Zwischenverkauf vorbehalten / Subject prior sales
        </Typography>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="search_number"
            label="Artikelnummer"
            value={query.search_number}
            onChange={handleChange}
          />
          <TextField
            id="search_text"
            label="Bezeichnung"
            value={query.search_text}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          {isLoading ? <LinearProgress /> : <Box />}
        </Box>
        <Box sx={{minHeight: 400}}>
      <Table
        sx={{
          // display: { xs: "block", sm: "block", md: "block", lg: "table" },
          // overflowX: "auto",
          whiteSpace: "nowrap",
          // width: "100%",
        }}
        aria-label="simple table"
      >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {absData.items.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.pk}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        </Box>
        <TablePagination
          rowsPerPageOptions={[absData.size]}
          component="div"
          count={absData.total}
          rowsPerPage={absData.size}
          page={absData.page}
          onPageChange={handleChangePage}
        />
      </Box>
    </Card>
  );
}
