import PropTypes from 'prop-types';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import Logo from './Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx, to }:any) => (
        <Logo />
);

LogoSection.propTypes = {
    sx: PropTypes.object,
    to: PropTypes.string
};

export default LogoSection;
