import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

import themeTypography from './typography';
import componentsOverride from './overrides';

let theme = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      light: "#8ccab4",
      main: "#329b8a",
      dark: "#757575",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#616161",
      dark: "#ba000d",
      contrastText: "#000",
    },
    // common: {
    //   black: '#000',
    //   white: '#fff'
    // },
    text: {
        primary: colors.grey[900],
        secondary: colors.grey[900],
        disabled: colors.grey[400]
    },
    // action: {
    //     disabled: colors.grey[300]
    // },
    // divider: colors.grey[400],
    // background: {
    //   paper: colors.grey['A100'],
    //   default: colors.grey['A100']
    // }
  },
  mixins: {
    toolbar: {
      minHeight: '48px',
      padding: '16px',
      '@media (min-width: 600px)': {
        minHeight: '48px'
      }
    }
  },
});

theme = createTheme(theme, {
  typography: themeTypography(theme),
  components: componentsOverride(theme)
});

export default theme;
