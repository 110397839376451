// material-ui
import { useTheme } from '@mui/material/styles';
import atlantaLogo from '../../assets/images/logo/atlanta.png'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Mantis" width="100" />
         *
         */
        <>
        {/* <svg width="118" height="35" viewBox="0 0 118 35" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
                <img
        //   height="undefined"
          width="118"
          src={atlantaLogo}
          alt={atlantaLogo}
        />
        {/* </svg> */}
        </>
    );
};

export default Logo;
