import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState, AppThunk } from '../../app/store';

export interface LoginState {
  token: string;
  status: 'isAuthenticated' | 'loading' | 'unauthenticated' | 'error';
}

const getToken = () => {
  const tokenString = localStorage.getItem('token') || '""';
  return JSON.parse(tokenString);
};

const initialState: LoginState = {
  token: getToken(),
  status: getToken() === '' ? 'unauthenticated' : 'isAuthenticated',
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
      state.status = 'isAuthenticated'
    },
    setLoading: (state) => {
      state.status = 'loading'
    },
    logout: (state) => {
      localStorage.removeItem('token')
      state.token = ''
      state.status = 'unauthenticated'
    },
    setError: (state) => {
      state.status = 'error'
    },
  },
});

export const { setToken, setLoading, logout, setError } = loginSlice.actions;

export const getLoginCredentials = (state: RootState) => state.login;
export const getLoginStatus = (state: RootState) => state.login.status;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const performLogin =
  (username: string, password: string, stayLoggedIn: boolean): AppThunk =>
    (dispatch, _) => {
      dispatch(setLoading());
      var bodyFormData = new FormData();
      bodyFormData.append('username', username);
      bodyFormData.append('password', password);

      axios({
        method: "post",
        url: "https://api.atlanta-service.com/store/token",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(function (response) {
        dispatch(setToken(response.data.access_token));
        if (stayLoggedIn) {
          localStorage.setItem('token', JSON.stringify(response.data.access_token))
        }
      })
        .catch(function (error) {
          dispatch(setError())
          // console.log(error);
        });
    };

export default loginSlice.reducer;
